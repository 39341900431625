import { useState } from 'react';
import { useAlertContext } from '../../Context/AlertContext';
import { useStores } from '../../../../root-store-context';
import { SubscriptionType } from '../../../../services/SubscriptionService';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import UpdateIcon from '@mui/icons-material/Update';
import classes from './CabinetSubscriptionPlans.module.css';
import { buildApiUrl } from '../../../../services/api';
import { CabinetPaymentHistory } from '../CabinetPaymentsHistory/CabinetPaymentsHistory';
import { Divider } from '@mui/material';

const subscriptionTypeMap: Record<string, string> = {
    TRIAL: 'Пробная подписка',
    MTH: 'Месяц',
    YEAR: 'Год',
    TEST: 'Тест',
    GIFT: 'Подарочная подписка',
};

function CabinetSubscriptionPlans() {
    // Добавляем состояние для проверки, подписан ли пользователь
    const { subscription } = useStores();
    const [isSubscribed, setIsSubscribed] = useState(subscription.data?.isActive);
    const [subscriptionType, setSubscriptionType] = useState(subscription.data?.type);
    const [subscriptionEndDate, setSubscriptionEndDate] = useState(subscription.data?.activeUntil);
    const showAlert = useAlertContext();


    const handleUnsubscribe = () => {
        // Логика для отписки (например, вызов API)
        console.log('Отписка выполнена');
        setIsSubscribed(false);
        setSubscriptionType('');
        setSubscriptionEndDate('');
    };



    const activeUntil = subscription.data?.activeUntil
        ? new Date(subscription.data.activeUntil).toLocaleDateString()
        : "Не установлена";

    const mapSubscriptionType = (type: string | undefined): string => {
        return subscriptionTypeMap[type || ''] || 'Неизвестно';
    };
    
    const handleRenewSubscriptions = async () => {
        try {
            const response = await fetch(buildApiUrl('/subscription/renew'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // 'x-cron-auth-token': process.env.REACT_APP_CRON_AUTH_TOKEN,
                },
                credentials: 'include', // Если используется сессия
            });

            if (response.ok) {
                showAlert('Подписки успешно обновлены', 'success');
            } else {
                showAlert('Ошибка при обновлении подписок', 'error');
            }
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
            showAlert('Ошибка при выполнении запроса', 'error');
        }
    };

    const handleSubscribe = async (type: SubscriptionType) => {
        const values = {
            message: `Подписка на ${type}`,
            type: type  // Добавляем тип подписки
        };

        try {
            await subscription.create({
                message: values.message,
                type: values.type // Передаем тип подписки
            });

            const paymentUrl = subscription.data?.paymentUrl;

            if (paymentUrl) {
                window.location.href = paymentUrl;
            } else {
                showAlert(
                    'Произошла ошибка',
                    'error'
                );
            }
        } catch (error) {
            console.error('Ошибка при создании подписки:', error);
            showAlert(
                'Произошла ошибка',
                'error'
            );
        }
    };


    return (
        <Box className={classes['cabinet-subscription-plans__container']}>
            {isSubscribed ? (
                <Box className={classes['cabinet-subscription-plans__status']}>
                    <Box>
                        <Typography variant="subtitle1" component="span">
                            Вы подписаны на тариф{' '}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1" component="b">
                            «{mapSubscriptionType(subscriptionType)}»
                        </Typography>
                    </Box>
                    <Typography variant="subtitle1">
                        Дата следующего списания: {activeUntil}
                    </Typography>
                    <Typography variant='body1'>
                        При отмене подписки приложение будет доступно до конца оплаченного периода.
                        После отмены автоматическое списание средств с карты будет остановлено.
                    </Typography>
                    <Button variant="contained" color="warning" onClick={handleUnsubscribe}>
                        Отменить подписку
                    </Button>
                </Box>
            ) : (
                    <>
                    <Typography mt={2}>Сейчас у вас нет активных подписок.</Typography>
                    <Typography>
                        Оформляя подписку, вы соглашаетесь с офертой и автоматическим списанием средств с вашей карты.
                        Вы сможете отменить подписку в любой момент после её оформления.
                        Приложение будет доступно до конца оплаченного по подписке периода.
                    </Typography>
                    <Box className={classes['cabinet-subscription-plans__list']}>
                        <Card elevation={4} className={classes['cabinet-subscription-plans__item']}>
                            <Typography variant="h3">Месяц</Typography>
                            <Typography variant="subtitle2">1 000 ₽</Typography>
                            <Typography sx={{ textAlign: 'center' }}>
                                30 дней доступа к приложению. Платёж будет списываться каждый месяц.
                            </Typography>
                            <Button onClick={() => handleSubscribe('MTH')} sx={{ marginBlockStart: 'auto' }} variant="contained">
                                Подписаться
                            </Button>
                        </Card>
                        <Card elevation={4} className={classes['cabinet-subscription-plans__item']}>
                            <Typography variant="h3">Год</Typography>
                            <Typography variant="subtitle2">10 000 ₽</Typography>
                            <Typography sx={{ textAlign: 'center' }}>
                                365 дней доступа к приложению. Экономия 2 тысячи в год. Платёж будет
                                списываться каждый год.
                            </Typography>
                            <Button onClick={() => handleSubscribe('YEAR')} sx={{ marginBlockStart: 'auto' }} variant="contained">
                                Подписаться
                            </Button>
                        </Card>
                        <Card elevation={4} className={classes['cabinet-subscription-plans__item']}>
                            <Typography variant="h3">Тестовая</Typography>
                            <Typography variant="subtitle2">50 ₽</Typography>
                            <Typography sx={{ textAlign: 'center' }}>
                                Тестовая подписка на один день. Для демонстрационных целей.
                            </Typography>
                            <Button onClick={() => handleSubscribe('TEST')} sx={{ marginBlockStart: 'auto' }} variant="contained">
                                Подписаться
                            </Button>
                        </Card>
                        <Card elevation={1} className={classes['cabinet-subscription-plans__item']}>
                            <Typography variant="h3">С тренером</Typography>
                            <Typography variant="subtitle2">90 000 ₽</Typography>
                            <Typography sx={{ textAlign: 'center' }}>
                                Тренировки по плану, составленному профессиональным тренером.
                            </Typography>
                            <Typography>Ждите анонса!</Typography>
                        </Card>
                    </Box>
                </>
            )}
            <CabinetPaymentHistory />
            <Button
                onClick={handleRenewSubscriptions}
                sx={{ marginTop: '60px' }}
                variant="contained"
                color="info"
                startIcon={<UpdateIcon />}
            >
                Обновить подписки
            </Button>
        </Box>
    );
}

export default CabinetSubscriptionPlans;
