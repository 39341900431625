import { useEffect, useState } from 'react';
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { buildApiUrl } from '../../../../services/api';

const subscriptionTypeMap: Record<string, string> = {
    TRIAL: 'Пробная подписка',
    MTH: 'Месяц',
    YEAR: 'Год',
    TEST: 'Тест',
    GIFT: 'Подарочная подписка',
};

const paymentStatusMap: Record<string, string> = {
    created: 'Создан',
    succeeded: 'Оплачен',
    pending: 'В обработке',
    canceled: 'Отменён',
};

export async function fetchPaymentHistory() {
    try {
        const response = await fetch(buildApiUrl('/payment/history'), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch payment history');
        }

        const data = await response.json();
        return data.payments;
    } catch (error) {
        console.error('Failed to fetch payment history:', error);
        return [];
    }
}

type Payment = {
    orderId: number;
    amount: number;
    status: string;
    createdAt: string;
    subscriptionType: string;  // Добавляем тип подписки
}

export const CabinetPaymentHistory: React.FC = () => {
    const [paymentHistory, setPaymentHistory] = useState<Payment[]>([]);

    const loadPaymentHistory = async () => {
        const payments = await fetchPaymentHistory();
        setPaymentHistory(payments);
    };

    useEffect(() => {
        loadPaymentHistory();
    }, []);

    const mapSubscriptionType = (type: string): string => {
        return subscriptionTypeMap[type] || type;
    };

    const mapPaymentStatus = (status: string): string => {
        return paymentStatusMap[status] || status;
    };

    return (
        <Box mt={4}>
            <Typography variant="h6">История оплат</Typography>
            <Button startIcon={<RefreshIcon />} variant="contained" color="primary" onClick={loadPaymentHistory} sx={{ marginBottom: 3, marginTop: 2 }}>
                Обновить статус оплат
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell>Номер заказа</TableCell>
                        <TableCell>Тариф</TableCell>
                        <TableCell>Сумма списания</TableCell>
                        <TableCell>Дата списания</TableCell>
                        <TableCell>Статус</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paymentHistory.map((payment, index) => (
                        <TableRow key={payment.orderId}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{payment.orderId}</TableCell>
                            <TableCell>{mapSubscriptionType(payment.subscriptionType)}</TableCell>
                            <TableCell>{payment.amount} ₽</TableCell>
                            <TableCell>{new Date(payment.createdAt).toLocaleDateString()}</TableCell>
                            <TableCell>{mapPaymentStatus(payment.status)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};